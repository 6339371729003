// 房源详情
<template>
  <div class="housing">
    <div class="housing_nav p-t-20 p-b-20 w-1200 margin-auto">
      <div class="housing_nav_top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>房源管理</el-breadcrumb-item>
          <el-breadcrumb-item>小区</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
          <el-breadcrumb-item class="color_blue"> 小区统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--total-container start-->
    <div class="total-container p-t-30 p-b-30 margin-auto">
      <div class="w-1200 margin-auto">
        <div class="total-title flex flex-between">
          <span class="t">小区统计</span>
          <div class="flexs">
            <div class="t-li now">
              <span class="icon m-r-10"></span>
              <span>现有</span>
            </div>
            <!-- <div class="t-li now">
              <span class="icon m-r-10"></span>
              <span>已租</span>
            </div>
            <div class="t-li sell">
              <span class="icon m-r-10"></span>
              <span>已售</span>
            </div> -->
            <div class="t-li un-traded">
              <span class="icon m-r-10"></span>
              <span>未交易</span>
            </div>
            <div class="t-li now-none">
              <span class="icon m-r-10"></span>
              <span>暂无</span>
            </div>
          </div>
        </div>
        <!--total-head start-->
        <div class="total-head m-t-30 m-b-30">
          <div class="head-li">
            <el-date-picker @change="getxiaoQuTongJi" value-format="yyyy-MM-dd" v-model="from.start_time" type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="head-line"></div>
          <div class="head-li">
            <el-date-picker @change="getxiaoQuTongJi" value-format="yyyy-MM-dd" v-model="from.end_time" type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="head-li m-l-20">
            <el-select clearable @change="getxiaoQuTongJi"  v-model="from.lou_number" placeholder="请选择">
              <el-option
                v-for="item in louList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div class="head-li m-l-20">
            <el-select clearable @change="getxiaoQuTongJi"  v-model="from.units" placeholder="请选择">
              <el-option
                v-for="item in unitsList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!--total-head end-->
        <div class="total-hd">
          <!-- <div class="li m-r-40 p-r-20">
            售卖量：<span class="red">{{ details.sales_count }}套</span>
          </div>
          <div class="li m-r-40 p-r-20">租赁量：<span class="red">{{ details.zu_count }}套</span></div> -->
          <div class="li m-r-40 p-r-20">现有：<span class="red">{{ details.existing }}套</span></div>
          <div class="li m-r-40 p-r-20">未交易：<span class="red">{{ details.Untraded }}套</span></div>
          <div class="li m-r-40 p-r-20">暂无：<span class="red">{{ details.None }}套</span></div>
        </div>

        <!-- 
          "status": 0=已租1=已售2=未交易3=暂无
          2=未交易 3=暂无 其他是现有
         -->
        <!--total-content start-->
        <div class="p-t-30 p-b-30 lou-container">
          <div class="lou-item" :class="{ first: louIndex === 0, last: louIndex === list.length - 1 }" v-for="(louItem, louIndex) in list" :key="index + '' + louItem.lou_number">
            <div class="lou-number">{{ louItem.lou_number }}</div>
            <div class="house-container">
              <div class="house-item" :class="{ blue:houseItem.status == 0 || houseItem.status == 1, pink:houseItem.status == 2, gray:houseItem.status == 3 }" v-for="(houseItem, houseIndex) in louItem.list" :key="houseItem.house_number">{{ houseItem.house_number }}</div>
            </div>
          </div>
        </div>
        <!--total-content end-->
      </div>
    </div>
    <!--total-container end-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      from:{
        xiaoqu_id:null,
        start_time:'',
        end_time:'',
        lou_number:'',
        units:''
      },
      details:{},//详情
      list:[],//
      options:[],
      louList:[],//楼号
      unitsList:[],//单元号
    };
  },
  methods: {
    getxiaoQuTongJi() {
      this.list = []
      this.$axios.xiaoQuTongJi(this.from).then(res=>{
        this.details = res.data
        this.list = res.data.noeList
      }).catch(error => {
        this.details = {}
        this.list = []
      })
    },
    getlouList () {
      this.$axios.louList(this.from).then(res=>{
        this.louList = res.data
      })
    },
    getunitsList () {
      this.$axios.unitsList(this.from).then(res=>{
        this.unitsList = res.data
      })
    }
  },
  created () {
    this.from.xiaoqu_id = this.$route.query.id
    this.getxiaoQuTongJi()
    this.getlouList()
    this.getunitsList()
  }
};
</script>

<style lang="less" scoped>
.m-l-20 {
  margin-left: 20px;
}
.total-container {
  max-width: 1400px;
  background: #fff;
  .total-title {
    padding-left: 10px;
    position: relative;
    .t-li {
      display: flex;
      margin-left: 40px;
      align-items: center;
      font-size: 14px;
      .icon {
        width: 18px;
        height: 18px;
        border-radius: 4px;
      }
    }
    .t-li.now .icon {
      background: #d5e3ff;
    }
    .t-li.sell .icon {
      background: #fee7cc;
    }
    .t-li.un-traded .icon {
      background: #ffcece;
    }
    .t-li.now-none .icon {
      background: #e6e6e6;
    }
  }
  .total-title:before {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 4px;
    height: 20px;
    background: #3273f6;
    margin-top: -10px;
    display: block;
    content: "";
  }
  .total-head {
    display: flex;
    align-items: center;
    .head-line {
      width: 20px;
      height: 1px;
      background: #ccc;
      margin: 0 10px;
    }
    .head-li {
      width: 240px;
    }
    /deep/ .el-date-editor.el-input {
      width: 240px;
    }
    /deep/ .el-select {
      width: 100%;
    }
  }
  .total-hd {
    display: flex;
    align-items: center;
    .li {
      font-size: 16px;
      color: #666;
    }
    .red {
      font-size: 22px;
      font-weight: 600;
      color: #ff2121;
    }
  }
  .lou-container {
    .lou-item {
      display: flex;
      .lou-number {
        background: #e9f0ff;
        width: 28px;
        text-align: center;
        color: #3273f6;
        font-size: 18px;
        line-height: 40px;
      }
      &.first .lou-number {
        border-radius: 999px 999px 0 0;
      }
      &.last .lou-number {
        border-radius: 0 0 999px 999px;
      }
      &.first.last .lou-number {
        border-radius: 999px;
      }
      &.last .house-container {
        margin-bottom: -10px;
      }
      .house-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px 10px 30px;
        // background: hotpink;
        .house-item {
          width: 110px;
          height: 40px;
          border-radius: 4px;
          font-size: 18px;
          text-align: center;
          line-height: 40px;
          margin: 0px 20px 10px 0;
          // background: pink;
        }
        .blue {
          background: #d5e3ff;
          color: #3273f6;
        }
        .orange {
          background: #fee7cc;
          color: #fda033;
        }
        .pink {
          background: #ffcece;
          color: #ff2a2a;
        }
        .gray {
          background: #e6e6e6;
          color: #333;
        }
      }
    }
  }
}
.color_blue {
  /deep/ .el-breadcrumb__inner {
    color: #3273f6;
  }
}
</style>
